@use "highlight.css";

/* Base Typography and Colors */
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500;600;700&display=swap");

:root {
    --bg-color: #1a1a1a;
    --bg-secondary: #252525;
    --text-color: #e0e0e0;
    --muted-color: #a0a0a0;
    --accent-color: #6d9eeb;
    --link-color: #81a9e3;
    --border-color: #333;
    --highlight-bg: #2d2d2d;
    --code-bg: #2c2c2c;
    --blockquote-bg: #2c3547;
    --separator-color: #444;
    --font-family: "IBM Plex Mono", monospace;

    /* Spacing variables for consistency */
    --space-xs: 0.25rem;
    --space-sm: 0.5rem;
    --space-md: 1rem;
    --space-lg: 1.5rem;
    --space-xl: 2rem;
    --space-xxl: 3rem;

    /* Container width variables */
    --container-width: 880px;
    --container-narrow: 650px;
}

/* Reset and Base Styles */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    font-size: 16px;
    scroll-behavior: smooth;
}

body {
    font-family: var(--font-family);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    max-width: var(--container-width);
    margin: 0 auto;
    padding: var(--space-xl);
    background-color: var(--bg-color);
    color: var(--text-color);
}

/* Typography */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    margin-bottom: var(--space-lg);
    line-height: 1.3;
    color: white;
}

h1 {
    font-size: 1.75rem;
    letter-spacing: -0.015em;
}

h2 {
    font-size: 1.5rem;
    margin-top: var(--space-xl);
}

h3 {
    font-size: 1.25rem;
    margin-top: var(--space-lg);
}

h4 {
    font-size: 1.1rem;
    margin-top: var(--space-lg);
}

p {
    margin-bottom: var(--space-md);
}

strong {
    color: white;
    font-weight: 600;
}

em {
    font-style: italic;
}

/* Links */
a {
    color: var(--link-color);
    text-decoration: none;
    transition: all 0.2s ease;
    border-bottom: 1px solid transparent;
}

a:hover,
a:focus {
    border-bottom: 1px solid var(--link-color);
    outline: none;
}

a:focus {
    outline: 2px solid var(--accent-color);
    outline-offset: 2px;
}

/* Lists */
ul,
ol {
    margin: 0 0 var(--space-lg) var(--space-xl);
}

li {
    margin-bottom: var(--space-sm);
}

/* Code and Preformatted Text */
code,
pre {
    font-family: var(--font-family);
    font-size: 0.9rem;
    background-color: var(--code-bg);
    border-radius: 3px;
}

code {
    padding: var(--space-xs) var(--space-sm);
}

pre {
    padding: var(--space-md);
    overflow-x: auto;
    border-left: 3px solid var(--accent-color);
    margin: var(--space-lg) 0;
    white-space: pre-wrap;
}

pre code {
    padding: 0;
    background-color: transparent;
}

/* Images */
img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: var(--space-lg) auto;
    border-radius: 4px;
}

/* Tables */
table {
    width: 100%;
    border-collapse: collapse;
    margin: var(--space-lg) 0;
    overflow-x: auto;
    display: block;
}

th {
    background-color: var(--highlight-bg);
    color: white;
    font-weight: 600;
    text-align: left;
}

th,
td {
    padding: var(--space-sm) var(--space-md);
    border: 1px solid var(--border-color);
}

tr:nth-child(even) {
    background-color: var(--bg-secondary);
}

/* Header */
.site-header {
    margin: 0 0 var(--space-xxl) 0;
    padding-bottom: var(--space-lg);
    border-bottom: 1px solid var(--border-color);
}

.site-title {
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: -0.02em;
    margin: var(--space-md) 0;
}

.site-title a {
    color: var(--accent-color);
    border-bottom: none;
}

.site-title a:hover,
.site-title a:focus {
    color: white;
    border-bottom: none;
}

.site-description {
    text-align: center;
    font-size: 1rem;
    color: var(--muted-color);
    margin-bottom: var(--space-lg);
}

.nav-links {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: var(--space-md);
    margin-top: var(--space-lg);
}

.nav-links a {
    font-weight: 500;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    padding: var(--space-xs) var(--space-sm);
    border-radius: 3px;
    border-bottom: none;
    color: var(--muted-color);
}

.nav-links a:hover,
.nav-links a:focus {
    color: var(--accent-color);
    background-color: var(--highlight-bg);
    border-bottom: none;
}

/* Posts */
.post {
    margin-bottom: var(--space-xxl);
    padding-bottom: var(--space-xl);
    border-bottom: 1px solid var(--border-color);
}

.post:last-of-type {
    border-bottom: none;
}

.post-title {
    margin-bottom: var(--space-sm);
}

.post-title a {
    color: white;
    border-bottom: none;
}

.post-title a:hover,
.post-title a:focus {
    color: var(--accent-color);
    border-bottom: none;
}

.post-date {
    font-size: 0.9rem;
    margin-bottom: var(--space-lg);
    font-weight: 500;
    color: var(--muted-color);
    letter-spacing: 0.03em;
}

.post-content {
    margin-bottom: var(--space-lg);
}

/* Blockquotes */
blockquote {
    margin: var(--space-lg) 0;
    padding: var(--space-md) var(--space-lg);
    border-left: 3px solid var(--accent-color);
    background-color: var(--blockquote-bg);
    font-style: italic;
    border-radius: 0 3px 3px 0;
}

blockquote p {
    margin-bottom: var(--space-sm);
}

blockquote p:last-child {
    margin-bottom: 0;
}

/* Source Attribution */
.source {
    margin-top: var(--space-lg);
    font-size: 0.9rem;
    color: var(--muted-color);
    padding: var(--space-sm) 0;
}

.source a {
    font-weight: 500;
}

/* Tags */
.tags {
    margin-top: var(--space-lg);
    font-size: 0.85rem;
    line-height: 1.8;
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-xs);
    align-items: center;
}

.tags-title {
    font-weight: 600;
    color: var(--accent-color);
    margin-right: var(--space-sm);
}

.tag-link {
    display: inline-block;
    margin-right: var(--space-xs);
    margin-bottom: var(--space-xs);
    padding: var(--space-xs) var(--space-sm);
    background-color: var(--highlight-bg);
    color: var(--muted-color);
    border-radius: 3px;
    border-bottom: none;
    white-space: nowrap;
}

.tag-link:hover,
.tag-link:focus {
    background-color: var(--accent-color);
    color: white;
    border-bottom: none;
}

/* Read more link */
.read-more {
    display: inline-block;
    margin-top: var(--space-sm);
    font-weight: 600;
    font-size: 0.9rem;
    padding: var(--space-xs) var(--space-sm);
    background-color: var(--highlight-bg);
    border-radius: 3px;
    transition: all 0.2s ease;
}

.read-more:hover,
.read-more:focus {
    background-color: var(--accent-color);
    color: white;
    border-bottom: none;
}

.read-more:after {
    content: " →";
}

/* Footer */
footer {
    margin-top: var(--space-xxl);
    padding-top: var(--space-lg);
    border-top: 1px solid var(--border-color);
    text-align: center;
    font-size: 0.9rem;
    color: var(--muted-color);
}

/* Archive link */
.archive-link {
    margin-top: var(--space-xl);
    text-align: center;
}

.archive-link a {
    display: inline-block;
    padding: var(--space-sm) var(--space-lg);
    background-color: var(--highlight-bg);
    border: 1px solid var(--border-color);
    border-radius: 3px;
    font-weight: 600;
    font-size: 0.9rem;
    transition: all 0.2s ease;
}

.archive-link a:hover,
.archive-link a:focus {
    background-color: var(--accent-color);
    color: white;
    border-color: var(--accent-color);
}

/* Archive page */
.archive-list {
    list-style-type: none;
    padding: 0;
    margin-left: 0;
}

.year-list {
    list-style-type: none;
    padding: 0;
    margin: 0 0 var(--space-xl) 0;
}

.archive-item {
    margin-bottom: var(--space-md);
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
}

.archive-date {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 0.9rem;
    color: var(--muted-color);
    min-width: 120px;
    margin-right: var(--space-md);
}

/* Skip to content - Accessibility */
.skip-to-content {
    position: absolute;
    left: -9999px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.skip-to-content:focus {
    position: fixed;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    padding: var(--space-sm) var(--space-md);
    background: var(--accent-color);
    color: white;
    font-weight: 600;
    z-index: 9999;
    border-bottom-right-radius: 3px;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    :root {
        /* Slightly adjust spacing for medium screens */
        --space-xl: 1.8rem;
        --space-xxl: 2.5rem;
    }

    html {
        font-size: 15px;
    }

    body {
        padding: var(--space-lg);
    }

    h1 {
        font-size: 1.6rem;
    }
    h2 {
        font-size: 1.4rem;
    }
}

@media (max-width: 576px) {
    :root {
        /* Further reduce spacing for small screens */
        --space-lg: 1.2rem;
        --space-xl: 1.5rem;
        --space-xxl: 2rem;
    }

    html {
        font-size: 14px;
    }

    body {
        padding: var(--space-md);
    }

    h1 {
        font-size: 1.5rem;
    }
    h2 {
        font-size: 1.3rem;
    }
    h3 {
        font-size: 1.15rem;
    }

    .site-title {
        font-size: 1.8rem;
    }

    .site-description {
        font-size: 0.9rem;
    }

    .nav-links {
        flex-direction: row;
        justify-content: center;
        gap: var(--space-sm);
    }

    .nav-links a {
        padding: var(--space-xs) var(--space-sm);
        font-size: 0.85rem;
    }

    .archive-item {
        flex-direction: column;
    }

    .archive-date {
        margin-bottom: var(--space-xs);
        margin-right: 0;
    }

    blockquote {
        padding: var(--space-sm) var(--space-md);
    }

    ul,
    ol {
        margin-left: var(--space-lg);
    }

    .post-date {
        margin-bottom: var(--space-md);
    }

    .tags {
        flex-wrap: wrap;
        gap: var(--space-xs);
    }

    .tag-link {
        margin-bottom: var(--space-xs);
    }

    table {
        font-size: 0.85rem;
    }

    th,
    td {
        padding: var(--space-xs) var(--space-sm);
    }
}

@media (max-width: 400px) {
    :root {
        /* Minimal spacing for very small screens */
        --space-md: 0.8rem;
        --space-lg: 1rem;
        --space-xl: 1.2rem;
    }

    html {
        font-size: 13px;
    }

    body {
        padding: var(--space-sm);
    }

    .site-title {
        font-size: 1.6rem;
    }

    h1 {
        font-size: 1.4rem;
    }
    h2 {
        font-size: 1.2rem;
    }

    .post {
        margin-bottom: var(--space-xl);
        padding-bottom: var(--space-lg);
    }

    pre {
        padding: var(--space-sm);
    }

    .nav-links {
        margin-top: var(--space-md);
    }

    .nav-links a {
        padding: var(--space-xs) var(--space-xs);
        font-size: 0.8rem;
    }

    .archive-link a {
        padding: var(--space-xs) var(--space-md);
        font-size: 0.85rem;
    }
}

/* Print styles */
@media print {
    body {
        background-color: white;
        color: black;
        font-size: 12pt;
        line-height: 1.5;
        padding: 0;
        margin: 0;
    }

    a {
        color: #000;
        text-decoration: underline;
    }

    .site-header,
    .nav-links,
    .tags,
    .archive-link,
    .read-more,
    footer {
        display: none;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: black;
        page-break-after: avoid;
    }

    img,
    pre,
    blockquote,
    table {
        page-break-inside: avoid;
    }

    pre,
    blockquote {
        border-left: 3px solid #ccc;
        background: #f9f9f9;
        color: #000;
    }

    a:after {
        content: " (" attr(href) ")";
        font-size: 90%;
    }
}

/* Tag Pages Styling */
.tag-description {
    margin-bottom: var(--space-xl);
    font-size: 1.1rem;
    color: var(--muted-color);
}

.tags-page {
    margin-top: var(--space-xl);
}

.tag-group {
    margin-bottom: var(--space-xl);
    padding-bottom: var(--space-lg);
    border-bottom: 1px solid var(--border-color);
}

.tag-group:last-child {
    border-bottom: none;
}

.tag-group h2 {
    display: inline-block;
    margin-bottom: var(--space-sm);
}

.post-count {
    font-size: 0.9rem;
    color: var(--muted-color);
    margin-left: var(--space-sm);
}

.tag-posts {
    list-style-type: none;
    padding: 0;
    margin: var(--space-md) 0 0 0;
}

.tag-posts li {
    margin-bottom: var(--space-sm);
    display: flex;
    flex-wrap: wrap;
}

.tag-posts .post-date {
    min-width: 100px;
    font-size: 0.9rem;
    color: var(--muted-color);
    margin-right: var(--space-md);
}

@media (max-width: 576px) {
    .tag-posts li {
        flex-direction: column;
    }

    .tag-posts .post-date {
        margin-bottom: var(--space-xs);
    }
}

/* Tag Cloud */
.tag-cloud {
    margin: var(--space-xl) 0;
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-sm);
}

.tag-cloud-item {
    display: inline-block;
    padding: var(--space-xs) var(--space-sm);
    background-color: var(--highlight-bg);
    border-radius: 3px;
    font-size: 0.9rem;
    transition: all 0.2s ease;
    border-bottom: none;
}

.tag-cloud-item:hover,
.tag-cloud-item:focus {
    background-color: var(--accent-color);
    color: white;
    border-bottom: none;
}

.tag-size-1 {
    font-size: 0.8rem;
}
.tag-size-2 {
    font-size: 0.9rem;
}
.tag-size-3 {
    font-size: 1rem;
}
.tag-size-4 {
    font-size: 1.1rem;
}
.tag-size-5 {
    font-size: 1.2rem;
}
