.highlight table td {
    padding: 5px;
}
.highlight table pre {
    margin: 0;
}
.highlight,
.highlight .w {
    color: #fbf1c7;
    background-color: #282828;
}
.highlight .err {
    color: #fb4934;
    background-color: #282828;
    font-weight: bold;
}
.highlight .c,
.highlight .ch,
.highlight .cd,
.highlight .cm,
.highlight .cpf,
.highlight .c1,
.highlight .cs {
    color: #928374;
    font-style: italic;
}
.highlight .cp {
    color: #8ec07c;
}
.highlight .nt {
    color: #fb4934;
}
.highlight .o,
.highlight .ow {
    color: #fbf1c7;
}
.highlight .p,
.highlight .pi {
    color: #fbf1c7;
}
.highlight .gi {
    color: #b8bb26;
    background-color: #282828;
}
.highlight .gd {
    color: #fb4934;
    background-color: #282828;
}
.highlight .gh {
    color: #b8bb26;
    font-weight: bold;
}
.highlight .k,
.highlight .kn,
.highlight .kp,
.highlight .kr,
.highlight .kv {
    color: #fb4934;
}
.highlight .kc {
    color: #d3869b;
}
.highlight .kt {
    color: #fabd2f;
}
.highlight .kd {
    color: #fe8019;
}
.highlight .s,
.highlight .sb,
.highlight .sc,
.highlight .dl,
.highlight .sd,
.highlight .s2,
.highlight .sh,
.highlight .sx,
.highlight .s1 {
    color: #b8bb26;
    font-style: italic;
}
.highlight .si {
    color: #b8bb26;
    font-style: italic;
}
.highlight .sr {
    color: #b8bb26;
    font-style: italic;
}
.highlight .sa {
    color: #fb4934;
}
.highlight .se {
    color: #fe8019;
}
.highlight .nn {
    color: #8ec07c;
}
.highlight .nc {
    color: #8ec07c;
}
.highlight .no {
    color: #d3869b;
}
.highlight .na {
    color: #b8bb26;
}
.highlight .m,
.highlight .mb,
.highlight .mf,
.highlight .mh,
.highlight .mi,
.highlight .il,
.highlight .mo,
.highlight .mx {
    color: #d3869b;
}
.highlight .ss {
    color: #83a598;
}
